import {
  browserTracingIntegration,
  init,
  replayIntegration,
} from "@sentry/astro";
import { SENTRY_DSN } from "astro:env/client";
import { options } from "./shared-options";

init({
  ...options,
  dsn: SENTRY_DSN,
  tunnel: "/api/sy",
  integrations: [browserTracingIntegration(), replayIntegration()],
});
